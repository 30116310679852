.hkb-site-search .hkb-site-search__field {
  border-radius: 5px !important;
  box-shadow: 0 0 0 5px rgba($color-accent, .9) !important;
  will-change: box-shadow;
  transition: box-shadow .3s ease;

  &:focus {
    box-shadow: 0 0 0 5px rgba($color-accent, 1) !important;
  }
}

.hkb-searchresults__article {
  border-bottom: 1px solid lighten($color-gray, 10%);

  .hkb-searchresults__title {
    color: $color-accent !important;
  }

  .hkb-searchresults__excerpt {
    color: $color-darkgray !important;
  }
}