.menu--main {
  .menu-level-0 {
    margin: 0 auto;
    width: 90%;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      width: 100%;
      margin: 0;
    }

    > .menu-item {
      padding: .5rem 1rem;

      @include media-breakpoint-up(sm) {
        padding: 1rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 2.5rem 0;
      }

      @include media-breakpoint-up(xl) {
        padding: 2.5rem 1rem;
      }

      > a {
        position: relative;
        display: inline-block;
        padding: .5rem;

        font-family: $font-secondary;
        font-size: 8vw;
        line-height: 1em;

        white-space: nowrap;

        @include media-breakpoint-up(md) {
          font-size: 5vw;
        }

        @include media-breakpoint-up(lg) {
          text-align: center;
          font-size: 1.8rem;
          padding: 2.5rem;
        }

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: .5rem;
          right: .5rem;
          transform: translateY(250%);

          height: .3rem;

          background-color: $color-accent;
          opacity: 0;

          transition: opacity .3s ease, transform .3s ease;

          @include media-breakpoint-up(lg) {
            left: 2.5rem;
            right: 2.5rem;
            top: 100%;
            transform: translateY(300%);
          }
        }
      }

      // Hover of .menu-item or active state
      &:hover {
        > a {
          color: $color-secondary;

          &:after {
            opacity: 1;
            transform: translateY(0);

            @include media-breakpoint-up(lg) {
              transform: translateY(0);
            }
          }
        }

        @media (hover: hover) {
          .menu-level-1 {
            opacity: 1;
            pointer-events: initial;
          }
        }
      }

      &.menu-item--active-trail {
        a {
          &:after {
            opacity: 1;
            transform: none;
            @include media-breakpoint-up(lg) {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
