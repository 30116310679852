// Colors
//
// Colors used in the site.
//
// All colors

// Theme colors
$white: #FFFFFF;
$black: #1F1F1F;
$color-primary: #1F1F1F;
$color-secondary: $white;
$color-accent: #FF7300;

$color-gray: #B2B1B1;
$color-darkgray: #292929;

$error-red: #BD2B20;
$succes-green: #39A723;


