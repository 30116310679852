.home {
  .ht-page.ht-page--sidebarright {
    padding-top: 10rem;
  }

  .ht-page__content {
    position: relative;

    > h2 {
      position: absolute;
      bottom: 100%;
      left: 0;
    }
  }
}