.hkb-article__content {

  .wp-block-separator {
    color: $color-accent;
  }

  p {
    font-size: 1.7rem;
    line-height: 1.5em;

    a {
      color: $color-accent;
      text-decoration: underline;


      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  table {
    th {
      background-color: $color-accent;
      color: $white;
    }

    td {
      color: $color-accent;
    }
  }

  .wp-block-quote {
    position: relative;
    background-color: $color-darkgray;
    border-left: none;
    padding: 5rem 2rem;
    margin: 5% auto;
    text-align: center;
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "“";
      font-size: 32rem;
      color: $white;
      opacity: .1;
      font-family: $font-secondary;
      position: absolute;
      left: 2rem;
      top: -8rem;
      z-index: -1;
    }

    p {
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 1.5em;
      color: $white;
    }

    cite {
      display: block;
      font-family: $font-secondary;
      font-size: 2.1rem;
      color: $color-accent;
      line-height: 2.4rem;
      margin: 2rem 0 .8rem;
    }
  }

  .wp-block-image.size-large {
    img {
      display: block;
      width: 100%;
      margin: 5% auto;
    }
  }

  .wp-block-ht-blocks-toggle {
    &.wp-block-hb-toggle--active {
      .wp-block-hb-toggle__title {
        background-color: $color-accent;
        border: 2px solid $color-accent;

        span {
          color: $white !important;
        }
      }
    }

    .wp-block-hb-toggle__title {
      background-color: transparent;
      border: 2px solid $color-accent;
      transition: background-color .3s ease;

      span {
        color: $color-accent !important;
      }
    }

    .wp-block-hb-toggle__content {
      border: 2px solid $color-accent;
      border-top: 0;
      border-radius: 0 0 5px 5px;
    }
  }

  .wp-block-hb-accordion {
    .wp-block-hb-accordion__section--active {
      .wp-block-hb-accordion__title {
        background-color: $color-accent;
        border: 2px solid $color-accent;

        span {
          color: $white !important;
        }
      }
    }

    .wp-block-hb-accordion__contentwrap {
      border: none !important;
    }

    .wp-block-hb-accordion__title {
      background-color: transparent;
      border: 2px solid $color-accent;
      transition: background-color .3s ease;

      span {
        color: $color-accent !important;
      }
    }

    .wp-block-hb-accordion__content {
      border: 2px solid $color-accent;
      border-top: 0;
      border-radius: 0 0 5px 5px;
    }
  }

  ul {
    li {
      position: relative;
      line-height: 1.25em;

      &::marker {
        content: none;
      }

      &::before {
        content: "";
        display: block;
        width: .6rem;
        height: .6rem;
        position: absolute;
        left: -2rem;
        top: .6rem;
        border-radius: 4px;
        background-color: $color-accent;
      }
    }
  }

  ol {
    li {
      &::before {
        background-color: transparent;
        font-size: 14px;
      }
    }
  }
}