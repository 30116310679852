//@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700');

$font-primary: 'Lora', serif;
$font-secondary: 'Bebas Neue', sans-serif;


// Lora
// -- Regular
@font-face {
  font-family: 'Lora';
  src: url('../fonts/Lora-Regular.woff2') format('woff2'),
  url('../fonts/Lora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('../fonts/Lora-Italic.woff2') format('woff2'),
  url('../fonts/Lora-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

// -- Bold
@font-face {
  font-family: 'Lora';
  src: url('../fonts/Lora-Bold.woff2') format('woff2'),
  url('../fonts/Lora-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('../fonts/Lora-BoldItalic.woff2') format('woff2'),
  url('../fonts/Lora-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}


// Bebas Neue
// -- Regular
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue.woff2') format('woff2'),
  url('../fonts/BebasNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

