.region--footer {

  nav[role="navigation"] {
    h2 {
      font-size: 2.4rem;
      line-height: 1.33em;
      margin: 0 0 1.2rem;
    }
  }

  ul[data-region="footer"] {
    font-size: 1.5rem;
    line-height: 1.5em;

    li {
      & + li {
        margin: .8rem 0 0;
      }
    }
  }

  .is-active {
    color: $color-accent;
    text-decoration: underline;
  }
}
