#block-languageswitcher {
  z-index: 20;

  position: relative;


  //@include media-breakpoint-up(lg) {
  //  position: absolute;
  //  top: 1rem;
  //  right: 1rem;
  //}

  input[type="checkbox"] {
    display: none;

    &:checked {
      & + label {
        opacity: 1;

        &:after {
          transform: translate(0, -2px) rotate(-180deg);
        }
      }

      & ~ ul {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  label {
    display: none;
    cursor: pointer;
    text-transform: uppercase;

    padding: 2rem 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-family: $font-secondary;
    font-size: 8vw;

    &:after {
      content: '';
      border-top: 5px solid $white;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      display: block;

      width: 5px;
      height: 2px;
      margin-left: 5px;

      transition: transform .6s ease;
    }

    @include media-breakpoint-up(md) {

    }

    @include media-breakpoint-up(md) {
      font-size: 5vw;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      display: flex;
      align-items: center;
    }

    opacity: .6;

    transition: opacity .3s ease;

    &:hover {
      opacity: 1;

      &:after {
        transform: translate(0, -2px) rotate(-180deg);
      }

      & + ul {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: 2px;
    margin-top: 2rem;

    transition: opacity .3s ease;

    &:hover {
      opacity: 1;
      pointer-events: initial;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      background-color: $color-accent;
      border: 1px solid $color-accent;
      display: block;
      position: absolute;
      left: 50%;
      top: 80%;
      opacity: 0;
      pointer-events: none;
      transform: translate(-50%, 0);
      z-index: 20;

      &:before {
        content: '';
        background-color: $color-accent;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        box-shadow: 0 0 10px rgba($black, .1);
        z-index: -1;
      }
    }

    li + li {
      border-left: 1px solid $color-secondary;

      @include media-breakpoint-up(lg) {
        border-left: none;
        border-top: 1px solid rgba($color-primary, .1);
      }
    }

    a {
      display: block;
      color: $white;
      text-align: center;
      padding: .6rem 1rem .5rem;

      font-family: $font-secondary;
      font-size: 2.1rem;

      transition: background-color .3s ease, opacity .3s ease;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        background-color: $color-accent;
        &:hover {
          background-color: darken($color-accent, 5%);
        }
      }

      &:hover {
        opacity: .6;
      }

    }
  }
}
