// Contents sidebar on pages
.hkb_widget_toc, .ht-mobile-toc {
  background-color: $color-darkgray !important;

  ol {
    margin: 0;

    li {
      padding: 0;

      &::before {
        content: none;
      }

      > a {
        padding: 1rem;
        background-color: transparent !important;
        will-change: background-color, opacity, color;
        transition: background-color .3s ease, color .3s ease, opacity .3s ease !important;

        &:hover {
          color: $color-accent;
        }
      }

      &.active {
        > a {
          background-color: $color-accent !important;

          &:hover {
            opacity: .6;
            color: $white;
          }
        }
      }
    }

    ol {
      position: relative;
      padding-left: 1.5rem;
      margin: .5rem 0 0 !important;


      &::before {
        content: "";
        display: block;
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: 0;
        top: 1rem;
        border-left: 1px solid rgba($white, .2);
        border-bottom: 1px solid rgba($white, .2);
      }
    }
  }
}