// Textstyles
//
// These are all the basic styles used on this site
// (You can overwrite this in specific cases)
//
// Styleguide Textstyles


// Title styling
%title {
  font-family: $font-secondary;
  line-height: 1.1em;
  color: $white;

  &.alt {
    font-family: $font-primary;
    font-weight: normal;
  }

  b, strong {
    font-weight: normal;
  }

  &.with-stripe {
    &:before {
      content: '';
      display: block;
      height: .8rem;
      width: 100%;
      max-width: 6.4rem;
      margin: 0 0 1.6rem;
      background-color: $color-accent;

      @include media-breakpoint-up(md) {
        margin: 0 0 2rem;
      }
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  @extend %title;
}

h1, .h1-style {
  font-size: 3.2rem !important;

  &.with-stripe {
    margin-bottom: 2rem;
  }

  &.hkb-article__title {
    color: $color-accent !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.8rem !important;
    margin-bottom: 2.5rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 4.4rem !important;
  }
}

h2, .h2-style {
  font-size: 2.4rem !important;

  @include media-breakpoint-up(md) {
    font-size: 2.8rem !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3.2rem !important;
  }
}

h3, .h3-style {
  font-size: 2.1rem !important;

  @include media-breakpoint-up(md) {
    font-size: 2.4rem !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.8rem !important;
  }
}

h4, .h4-style {
  font-size: 1.9rem !important;

  @include media-breakpoint-up(md) {
    font-size: 2.2rem !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.6rem !important;
  }
}

h5, .h5-style {
  font-size: 1.8rem !important;

  @include media-breakpoint-up(lg) {
    font-size: 2.1rem !important;
  }
}

h6, .h6-style {
  font-size: 1.6rem !important;

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem !important;
  }
}

// Text styling
p {
  font-size: 1.5rem;
  line-height: 1.6em;
  margin: 0;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }

  & + p {
    margin: 1em 0 0;
  }
}

a {
  text-decoration: none;
  color: $color-secondary;
  transition: color .3s ease;
  font-weight: normal;

  &:hover {
    text-decoration: none;
    color: $color-accent;
  }

  .paragraph &, .node & {
    text-decoration: underline;
    color: $color-accent;
    //font-weight: bold;

    &:hover {
      text-decoration: none;
      color: $color-secondary;
    }
  }

  .file & {
    color: #fff;
  }
}

// List styling
ul {
  padding: 0;
  margin: 0;
  list-style: none;

  .field--name-field-description & {
    margin: 1rem 0;
  }

  &.checklist, .field--name-field-description & {
    > li {
      position: relative;
      padding: 0 0 0 3.2rem;
      line-height: 1.5em;

      &:before {
        content: '';
        display: block;
        width: 1.6rem;
        height: 1.2rem;
        position: absolute;
        left: 0;
        top: .3em;

        background-image: url(../img/arrow--orange.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      & + li {
        margin-top: 2rem;
      }
    }
  }
}

// Ordered list styling
ol {
  padding: 0;
  margin: 0;
  list-style: none;
  counter-reset: ol-counter;

  .field--name-field-description & {
    margin: 1rem 0;
  }

  > li {
    position: relative;
    display: block;
    counter-increment: ol-counter;
    padding-left: 3rem;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      flex: 0 0 auto;
      content: counter(ol-counter) ". ";
      color: $color-accent;
      font-family: $font-secondary;
      font-size: 2.1rem;
      line-height: 1.25em;
      display: block;
      margin: 0 1.5rem 0 0;
    }

    & + li {
      margin-top: 2rem;
    }
  }
}

// audio tag
//audio {
//  background-color: $color-accent;
//}
