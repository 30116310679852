.ht-pageheader {
  background-color: $color-primary;
}

.hkb-breadcrumbs {
  li {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;

    &::before {
      content: none !important;
    }

    &::after {
      content: "/";
      margin: 0 .8rem;
      color: $color-accent;
    }

    a {
      color: $color-accent;
      font-size: 1.5rem;

      &:hover {
        color: $white;
      }

      svg {
        display: none !important;
      }
    }
  }
}