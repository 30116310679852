.region--header {
  .container {
    .hamburger {
      cursor: pointer;
      padding: 1rem 0 1rem 1rem;

      .icon {
        width: 20px;
        height: 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        %hamburger-layer {
          background-color: $color-secondary;
          height: 3px;
          width: 100%;
          border-radius: 5px;
          transition: width .3s ease, transform .3s ease;
        }

        .top {
          @extend %hamburger-layer;
        }

        .mid {
          @extend %hamburger-layer;
        }

        .bot {
          @extend %hamburger-layer;
        }
      }

      &:hover {
        .icon {
          .top {
            width: 60%;
          }

          .mid {
            width: 90%;
            transition: opacity .3s ease, transform .3s ease;
          }

          .bot {
            width: 75%;
          }
        }
      }
    }


    @include media-breakpoint-down(md) {
      .menu--main {
        display: block !important;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-primary;
        //
        //overflow: scroll;

        padding: 15rem 0 0;


        opacity: 0;
        pointer-events: none;

        &:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 40%;
          width: 17rem;
          height: 20rem;
          background-image: url(../../img/style-elements/circle-27--orange.svg);
          background-size: contain;
          background-position: left center;
          background-repeat: no-repeat;
          transform: translate(-45%, -50%) rotate(180deg);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .menu--main {
        padding: 10rem 0 0;
      }
    }
  }
}
