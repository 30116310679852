// Base
//
// This is the base documentation.
//
// Styleguide Base

:root {
  font-size: 10px; // => Set font-size to 10px, so rem's are easier to calculate
}

* {
  box-sizing: border-box;

  &::selection {
    background: $color-accent;
    color: $color-secondary;

    .paragraph--type--orange-blok-call-to-action .bot &, #download-rapport &, .call-to-action-block & {
      background: $color-primary;
    }
  }

  &::-moz-selection {
    background: $color-accent;
    color: $color-secondary;

    .paragraph--type--orange-blok-call-to-action .bot & {
      background: $color-primary;
    }
  }
}

body {
  background-color: $color-primary;
  color: $color-secondary;
  font-family: $font-primary;
  font-size: 1.6rem;
  line-height: 1em;

  .dialog-off-canvas-main-canvas {
    overflow: hidden;
  }
}

select::-ms-expand {
  display: none;
}

// The default 'currentColor' is the secondary color
svg {
  color: $color-secondary;
}

input {
  border: none;
  border-radius: 0;
}

.blazy {
  .media {
    background-color: $color-darkgray;

    &:before {
      background-color: $color-accent;
    }
  }
}

