.wp-block-htgb-block-glossary.hg-glossary {
  margin: 5% 0;


  .hg-search input {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $color-accent;
    color: $color-accent;
    font-weight: 500;
    background-color: transparent;

    &:focus {
      border-bottom-color: $white;
      outline: none;
    }
  }

  .hg-nav {
    align-items: center;
    justify-content: flex-start;

    [data-name="all"] {
      @extend .cta;
      height: 4rem !important;
      margin-right: 2rem;
    }

    a {
      font-family: $font-secondary !important;
      font-size: 1.7rem;
    }
  }

  .hg-letter-section {
    background-color: $color-darkgray;
    border-radius: 5px;
    padding: 2rem 2.5rem;
    margin-bottom: 1.6rem;

    .hg-content__letter {
      padding: 0;
      margin-bottom: 0;
      background-color: transparent;
      color: $color-accent;
    }

    .hg-item {
      border-top: 1px solid $color-accent;
      padding: 2rem 0 0;
    }
  }
}