.menu--main {
  .menu-level-2 {
    padding: 3rem 0 3rem 8.1rem;

    .menu-item {
      a {
        font-family: $font-secondary;
        font-size: 1.8rem;
        color: $color-accent;
        transition: color .3s ease;

        &:hover {
          color: $color-secondary;
        }
      }

      & + .menu-item {
        margin: 1.5rem 0 0;
      }
    }
  }
}
