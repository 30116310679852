#block-footer-menu-social {
  ul[data-region="footer"] {
    display: flex;
    margin: 2rem 0 0;

    li {
      & + li {
        margin: 0 0 0 1.5rem;
      }

      a {
        display: block;
        max-width: 4rem;
        max-height: 4rem;

        svg {
          //height: 100%;
          width: 100%;
          color: $color-accent;

          #background, #Mask {
            transition: stroke .3s ease, fill .3s ease, color .3s ease;
          }

          & + span {
            display: none;
          }
        }

        &:hover {
          svg {
            color: $white;

            #background {
              stroke: $color-accent;
              fill: $color-accent;
            }
          }
        }
      }
    }
  }
}
