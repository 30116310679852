.menu--main {

  h3.container {
    font-family: $font-primary;
    font-size: 1.6rem;
    color: $color-gray;
    letter-spacing: 0;
    line-height: 1.5em;
    margin: 0 auto 1.5em;
  }

  .menu-level-1 {
    opacity: 0;
    pointer-events: none;

    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    background: $color-primary;

    box-shadow: 0 11px 12px 0 rgba(0, 0, 0, 0.20);
    transition: opacity .3s ease;

    padding: 3rem 3rem 6rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 200px;
      z-index: -1;
    }
    
    .menu--items {
      display: flex;
      align-items: stretch;

      .col {
        > a {
          display: block;

          & + a {
            margin: 5rem 0 0;
          }
        }

        &:last-of-type {
          background-color: $color-darkgray;
          transition: background-color .3s ease;

          a {
            height: 100%;
            padding: 10%;

            h3 {
              @extend .alt;
              margin: 0 0 2.5rem;

              & + div {
                @extend .arrow-link;
              }
            }
          }

          &:hover {
            background-color: lighten($color-darkgray, 5%);

            a {
              h3 {
                & + div {
                  @extend .active-hover;
                }
              }
            }
          }
        }
      }
    }
  }
}
