.archive {
  .ht-page.ht-page--sidebarright {
    padding-top: 15rem;
  }

  .ht-page__content {
    position: relative;

    .ht-categoryheader {
      position: absolute;
      bottom: 100%;
      left: 0;
    }
  }
}

.ht-articlelist {
  > li {
    border-bottom: none;
    padding: 0;
    margin-bottom: 1.4rem;

    a {
      position: relative;
      background-color: $color-darkgray;
      display: block;
      padding: 2.5rem 5rem 2.5rem 2.5rem;
      transition: background-color .3s ease;

      > svg {
        position: absolute;
        top: 25px;
        right: 25px;
        color: $color-accent;
        will-change: transform, color;
        transition: transform .3s ease, color .3s ease;
      }


      &:hover {
        background-color: lighten($color-darkgray, 10%);

        > svg {
          color: $white;
          transform: translate(10px, 0);
        }
      }

      h2 {
        color: $color-accent !important;
        line-height: 1.1em !important;
      }
    }
  }
}