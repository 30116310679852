.hkb-article__content table {
  border: 1px solid #434343;

  tbody {
    tr {
      border-bottom: 1px solid #434343;
    }

    th, td {
      border-right: 1px solid #434343;
      padding: 1rem;
      color: $white;
    }

    tr:nth-child(odd) {
      th, td {
        background: #1f1f1f;
      }
    }

    tr:nth-child(even) {
      th, td {
        background: #292929;
      }
    }
  }
}