.release-note--teaser {
  height: 100%;
  display: flex;
  flex-direction: column;

  text-align: left;
  background-color: $color-darkgray;
  transition: background-color .3s ease;
  text-decoration: none !important;

  h2 {
    @extend .h3-style;
  }

  header {
    position: relative;
    flex: 0 0 auto;
    height: 1px;
    padding-bottom: 56.25%;
    overflow: hidden;

    .header-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: transform .6s ease;

    }
  }

  main {
    flex: 1 1 auto;
    padding: 5%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    p.date {
      display: inline-block;
      padding: .5rem .8rem;
      font-size: 1.3rem;
      color: $color-accent;
      line-height: 1.6rem;
      background-color: $color-primary;
      border-radius: 2px;
      margin: 0 0 .8rem;
      transition: background-color .3s ease, color .3s ease;
    }

    h3, h2 {
      font-family: $font-primary;
      font-weight: bold;
      transition: color .3s ease;
      font-size: 2.4rem;
      line-height: 1.5em;
      margin: .8rem 0 1.6rem;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 2.1rem;
      }
    }

    .link {
      display: flex;
      justify-content: flex-end;
      margin: 1.6rem 0 0;

      svg {
        color: $color-accent;
        transition: color .3s ease, transform .3s ease;
      }
    }
  }

  &:hover {
    background-color: $color-secondary;
    color: $color-primary;

    header {
      .blazy {
        transform: scale(1.05);
      }
    }

    main {
      p.date {
        color: $color-primary;
        background-color: $color-accent;
      }

      h3, h2 {
        color: $color-primary !important;
      }

      .link {
        svg {
          //color: $color-secondary;
          transform: translate(.5rem, 0);
        }
      }
    }
  }
}

.ht-postlist {


  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin: 0 0 5% 0;
    padding: 0;
    border: none;

    @include media-breakpoint-up(md) {
      flex: 0 0 30%;
      max-width: 30%;
      margin: 0 0 5% 5%;
      &:first-child {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;

        .release-note--teaser {
          flex-direction: row;

          header {
            flex: 0 0 60%;
            height: 100%;
            padding-bottom: 30%;
          }
        }
      }

      &:nth-child(3n - 1) {
        margin-left: 0;
      }
    }


  }
}