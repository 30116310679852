.region--header {
  .logo {
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      padding: 2.5rem 0;
    }

    a {
      display: block;

      svg {
        max-height: 40px;
        width: auto;
        transition: color .3s ease;

        @include media-breakpoint-up(lg) {
          max-height: 65px;
        }
      }

      &:hover {
        svg {
          color: $color-accent;
        }
      }
    }
  }
}
