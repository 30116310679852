.control-panel {
  text-align: left;
  margin: 1rem auto 0;
  width: 90%;
  padding: 1rem 1.5rem;

  @include media-breakpoint-up(lg) {
    padding: 0;
    width: auto;
    margin: 0 0 0 3rem;
  }
}

#block-mainmenu {
  > h2.visually-hidden {
    display: none;
    @extend .container;
    @extend .container--mobile;

    @include media-breakpoint-down(md) {
      display: block;
      position: relative !important;
      overflow: visible;
      clip: initial;
      width: 90%;
      height: auto;

      font-family: $font-secondary;
      font-size: 1.7rem;
      color: $color-accent;
      text-align: left;
      line-height: 1.1em;
    }
  }
}
