.hkb-archive .hkb-category__link, .hkb-archive .hkb-category {
  position: relative;

  > a {
    padding: 2rem !important;
    align-items: flex-start !important;
    align-content: flex-start !important;

    .hkb-category__iconwrap {
      align-self: auto !important;
      margin-bottom: 1rem !important;

      > svg {
        position: relative !important;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
      }
    }
  }

  > svg {
    position: absolute;
    top: 25px;
    right: 25px;
    color: $color-accent;
    will-change: transform, color;
    transition: transform .3s ease, color .3s ease;
  }

  &:hover {
    > svg {
      color: $white;
      transform: translate(10px, 0);
    }
  }

  .hkb-category__description {
    color: rgba($white, .8) !important;
    font-size: 13px !important;
  }
}