.ht-post.release-notes.type-release-notes {
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    color: $color-accent !important;
    text-align: center;
    margin-bottom: 5rem;
  }

  .header-image {
    width: 100%;
    padding-bottom: 56.25%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .ht-post__content {
    max-width: 800px;
    margin: 0 auto;
  }
}

.ht-page__content {
  width: 100%;

  h1 {
    margin-bottom: 5rem;
  }
}