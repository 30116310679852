.container {
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1500px) {
    max-width: 1480px;
  }

  // Adds a width of 90% on mobile devices
  &--mobile {
    @include media-breakpoint-down(sm) {
      width: 90%;
    }
  }
}



