// Buttons styling
//
// Style all the buttonz...

// Regular square 'call to action'
.cta, .hkb_widget_exit__btn {
  cursor: pointer;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  height: 5rem !important;
  padding: 0 2rem !important;
  background-color: $color-accent;
  color: $color-secondary;
  border-radius: 4px !important;
  text-decoration: none !important;

  // Text styling
  font-family: $font-secondary;
  font-size: 1.8rem !important;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  transition: background-color .3s ease;

  &:hover, &.active-hover {
    background-color: darken($color-accent, 5%);
    color: $white !important;
  }

  &--black {
    background-color: $color-primary;
    color: $color-secondary !important;

    &:hover, &.active-hover {
      background-color: lighten($color-primary, 5%);
      color: $color-secondary !important;
    }
  }

  &--white {
    background-color: $color-secondary;
    color: $color-primary !important;

    &:hover, &.active-hover {
      background-color: darken($color-secondary, 5%);
      color: $color-primary !important;
    }
  }

  &--transparent {
    background-color: transparent !important;
    color: $color-secondary !important;
    transition: color .3s ease;
    will-change: color;

    &:hover, &.active-hover {
      background-color: transparent !important;
      color: $color-accent !important;
    }
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > * {
    flex: 0 0 auto;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

// Arrow link
.arrow-link {
  font-family: $font-secondary;
  font-size: 1.8rem;
  color: $color-accent;
  text-align: left;
  line-height: 1.33em;

  display: flex;
  align-items: center;

  text-decoration: none !important;

  &:after {
    content: '';
    display: block;
    background-image: url(../img/arrow--orange.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 2rem;
    height: 1.5rem;
    margin: 0 0 0 1.6rem;
    transition: transform .3s ease;
  }

  &:hover, &.active-hover {
    &:after {
      transform: translate(.5rem);
    }
  }
}
