#block-footer-menu-disclaimer {
  ul {
    display: flex;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    li {
      & + li {
        margin: 0 0 0 2.5rem;
      }

      @include media-breakpoint-down(sm) {
        margin: 0 0 1rem;

        & + li {
          margin: 0 0 1rem;
        }
      }

      a {
        font-size: 1.4rem;
        color: $color-gray;
        line-height: 1.5em;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}
