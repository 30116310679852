/* Header */
.site-logo {
  svg {
    max-height: 65px;
    width: auto;
    color: #ffffff;
    will-change: color;
    transition: color .3s ease;
  }

  &:hover {
    svg {
      color: $color-accent;
    }
  }
}

.nav-header__menuwrapper {
  li {
    padding: 0 2.5rem !important;

    a {
      position: relative;
      padding: 2.5rem 0 !important;
      background-color: $color-primary !important;
      font-family: $font-secondary !important;
      font-size: 1.8rem !important;
      text-align: center !important;

      @media (min-width: 768px) {
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: .5rem;
          right: .5rem;
          transform: translateY(250%);
          height: .3rem;
          background-color: #FF7300;
          opacity: 0;
          transition: opacity .3s ease, transform .3s ease;
        }
      }


      &:hover {
        color: $color-accent !important;

        @media (min-width: 768px) {
          color: $white !important;
          &::after {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &.current-menu-item {
      a {
        &::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}