.wp-block-ht-blocks-tabs {


  .wp-block-hb-tabs__head {
    background-color: $color-darkgray !important;
    border: none !important;
    opacity: .4;
    transition: opacity .3s ease;

    &::before {
      content: none;
    }

    &:hover {
      opacity: .8;
    }

    &[data-hb-tabs-tab--state="active"] {
      opacity: 1;
      background-color: $color-darkgray !important;

      .wp-block-hb-tabs__head_item {
        color: $color-accent !important;
      }
    }
  }

  .wp-block-hb-tabs__content {
    background-color: $color-darkgray !important;
    border: none !important;
    padding: 3rem 4rem;

    p {
      margin: 0;

      & + p {
        margin-top: 1rem;
      }
    }
  }
}