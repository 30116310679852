.region--footer {
  background-color: $color-primary;
  border-top: 4px solid $color-accent;

  // THIS IS THE PADDING FOR THE CHATLIO
  padding-bottom: 50px;

  ul {
    a {
      color: $white !important;
      font-weight: 600;

      &:hover {
        color: $color-accent !important;
      }
    }
  }

  // The main footer, with all the navigations and stuff
  footer.main {
    padding: 4rem 0;
    display: flex;
    margin: 0 auto;

    // Contact info
    .contact-info {
      a {
        display: inline-block;
      }

      svg {
        height: 3.8rem;
        width: 8rem;
        margin: 0 0 1rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 1.5em;
      }

      //br {
      //  display: none;
      //}

      a {
        font-size: 1.5rem;
        line-height: 1.5em;
        color: $color-accent;

        &:hover {
          color: $color-secondary;
        }
      }
    }

    .social {
      margin: 4rem 0;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      .iso {
        display: none;

        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
          margin: 3rem 0 0;
        }

        svg {
          flex: 0 0 auto;
          width: 4rem;
          height: 3.4rem;
          margin: 0 .8rem 0 0;
        }

        p {
          flex: 1 1 auto;
          font-family: $font-secondary;
          font-size: 1.5rem;
          color: $color-secondary;
          line-height: 1em;
        }
      }
    }
  }

  // Disclaimer, the bottom part of the footer
  footer.disclaimer {
    border-top: 1px solid rgba($color-gray, .5);
    padding: 2.5rem 0;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .copyright {
      font-family: $font-secondary;
      font-size: 1.5rem;
      color: $color-gray;
      line-height: 1.33em;

      @include media-breakpoint-down(sm) {
        margin: 1rem 0 0;
        order: 2;
      }
    }

    .disclaimer-menu {
      @include media-breakpoint-down(sm) {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }
}
