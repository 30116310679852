.hkb-category {
  .hkb-category__link {
    display: block;
    position: relative;

    padding: 2.5rem 6rem 2.5rem 2.5rem !important;

    svg {
      position: absolute;
      top: 25px;
      right: 25px;
      color: $color-accent;
      will-change: transform, color;
      transition: transform .3s ease, color .3s ease;
    }

    &:hover {
      background-color: lighten($color-darkgray, 10%);

      svg {
        color: $white;
        transform: translate(10px, 0);
      }
    }
  }
}